import React from 'react';
import styled from 'styled-components';
// import CSLTable from '../../Common/CSLTable';
import CSLTable from '../Common/MCCTable';
import { FaEdit, FaTimes,FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import EditModal from './EditModal';
import Store from '../../Common/Store'
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import CSLLoader from './../Common/CSLLoader';
import VerticalEllipsisMenuFolderConflictTypes from "./VerticalEllipsisMenuFolderConflictTypes";
import AlertBox from '../Common/AlertBox';

const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #3f5a75;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 37%;
    font-size: 13px;
    height: 43px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
    background-color: #ffffff;
    padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    box-sizing: border-box;	
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class ConflictTypes extends React.Component
{
    state = {
        client_conflict_types: null,
        client_conflict_types_active_inactive: null,         
        show_edit_modal: null,
        curid: null,     
		isLoadedIcon: false,
        alert_param: null,
    };
    constructor(props) {
        super(props);        
    }

    componentDidMount()
	{
        this.setState({
            client_conflict_types_active_inactive: this.props.client_conflict_types, 
            client_conflict_types: this.props.client_conflict_types 
        });
    }
    componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {                  
			this.setState({
                client_conflict_types_active_inactive: this.props.client_conflict_types, 
                client_conflict_types: this.props.client_conflict_types,
                isLoadedIcon: false 
            });
		}
    }

    handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);	
        let {client_conflict_types} = this.state;
        client_conflict_types[section_id.toString()].isActive = client_conflict_types[section_id.toString()].isActive ? 0 : 1;
        console.log("client_conflict_types==>", this.state.client_conflict_types);
        this.setState({isLoadedIcon: true})
		this.props.updateConflictTypes(this.state.client_conflict_types);
	}

    tableData = () => {   
        let conflictTypeObject = this.state.client_conflict_types_active_inactive;        
        let ret = {data: [], columns: []};        
                ret.columns =[{Header: 'Type', accessor: 'type', minWidth: 240, headerStyle: {textAlign: 'left'}},
                {'Header' : 'Req. Comments', accessor: 'commentsreq', Cell: row => (
                    <div>
                        {
                            (()=> {
                                if (row.original.req_comments === 1) {
                                    return(<div style={{fontSize:"21px", float: "center"}} ><IoIosCheckboxOutline /></div>);
                                } else {
                                    return(<div  style={{fontSize:"21px", float: "center"}} ><IoIosSquareOutline /></div>);
                                }
                            })()
                        }
                    </div>
                ),  width: 120, headerStyle:{textAlign: 'center'}, style: {textAlign: 'center'}},
                {'Header' : 'Status', Cell: row => (
                    <div>                         
                        <Toggle
                            style={{float:'left'}}
                            id={row.original.id}
                            name={row.original.id}
                            checked={row.original.status === "Active" ? true : false}
                            icons={false}	
                            onChange={this.handlenaSectionIncluded(row.original.id)}						
                        />
                    </div>
                    ), filterable: false,
                    sortable: false,headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},
                {'Header' : 'Action', Cell: row => (                    
                    <div>                                
                            <VerticalEllipsisMenuFolderConflictTypes editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                    </div>
                ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
            ];       
        for(let k in conflictTypeObject){
            let elem = {
		        'type' : conflictTypeObject[k].title,
                'status': conflictTypeObject[k].isActive === 1 ? "Active" : "Inactive",
                'id': k,
                'isdelete': conflictTypeObject[k].isDelete,
                'req_comments' : 'isComments' in conflictTypeObject[k] ? conflictTypeObject[k].isComments : 0
            }
            ret.data.push(elem);
        }
        ret.data.sort(function(a,b) {return (a.type.toLowerCase() > b.type.toLowerCase()) ? 1 : ((b.type.toLowerCase() > a.type.toLowerCase()) ? -1 : 0);} )
        Store.updateStore('table_cur_data', ret.data)        
        return ret;        
    }

    showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            this.setState({ show_edit_modal: true, curid: curid});
		}else if(obj === 'Delete'){
            if(isdelete === 0){
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this conflict type from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteConflictTypes, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This conflict type has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

    updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

    updatedeleteConflictTypes = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null, isLoadedIcon: true})        
        console.log("id",stack.id)
        delete this.state.client_conflict_types[stack.id.toString()];
        delete this.state.client_conflict_types_active_inactive[stack.id.toString()];
        this.props.deleteConflictValueSave(this.state.client_conflict_types);           
    }    
    
    saveConflictTypeValue = (id,type,des,status,isdelete,isSave, isComments) => {
        console.log(id,type,des,status)
        let val = {}
        val.title = type;
        val.description = des;
        val.isActive = status;
        val.isDelete = isdelete;
        val.isComments = isComments;
        this.state.client_conflict_types[id] = val;
        this.state.client_conflict_types_active_inactive[id] = val;        
        this.setState({client_conflict_types: this.state.client_conflict_types,
            client_conflict_types_active_inactive: this.state.client_conflict_types_active_inactive,
            show_edit_modal: null,
            isLoadedIcon: true
        })   
        if(isSave === 1){
            this.props.addValueSave(this.state.client_conflict_types);
        }else{
            this.props.editValueSave(this.state.client_conflict_types);
        }        
    }

    closeModal = (event) => {        
        this.setState({show_edit_modal: null })
    }
    
    addConflictType = (event) => {
        event.preventDefault();        
        this.setState({ show_edit_modal: true, curid: 0});
    }   
    
    render()    
	{      
        if(!this.state.client_conflict_types){
            return(<div>Loading...</div>);
        }        
		return(
        <div>
            {
				(() => {
					if (this.state.show_edit_modal !== null) {
						return (<div><InactiveOverlay style={{height: "105%"}}/><EditModal saveConflictValue={this.saveConflictTypeValue} client_conflict_types={this.state.client_conflict_types} curid={this.state.curid} closeModal={this.closeModal} /></div>);
					}
				})()
			}  
            {
                (() => {
                    if(this.state.isLoadedIcon){
                        return <div><CSLLoader style={{position: 'absolute'}}/></div>
                    }
                })()
            }         
            
            <EmailContainer>
                <EmailInnerContainer>
                    <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                        Conflict Types
                        <div style={{float:'right'}}>
                            <div onClick={this.addConflictType} style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                                <FaPlusCircle  /> 
                            </div>	
                            <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
                                <FaEllipsisV  /> 
                            </div>
                        </div>
                    </EmailTopHeader>                            								
                    <CSLTable add={false} processData={this.tableData} tableRows="10" />
                </EmailInnerContainer>
            </EmailContainer> 
            <AlertBox alertParam = {this.state.alert_param}/> 
        </div>
        );
	}
}

export default ConflictTypes;