import React from 'react';
import styled from 'styled-components';
import WelcomeDateTime from './WelcomeDateTime';

const BannerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
`;
const Banner = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #37ada7;
    border-radius: 4px;
    height: 40px;
    width: 100%;
`;
const WelcomeText = styled.div`
    float: left;
    color: #ffffff;
    font-weight: 600;
    padding-top: 11px;
    padding-left: 20px;
`;

class WelcomeBanner extends React.Component
{
    render()
    {
        return (
            <BannerContainer>
                <Banner>
                    <WelcomeText>{ this.props.welcome }</WelcomeText>
                    <WelcomeDateTime />
                    <div style={{clear: "both"}}></div>
                </Banner>
            </BannerContainer>
        );
    }
}

export default WelcomeBanner;