import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import { FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import CSLTable from '../Common/MCCTable';
import VerticalEllipsisMenuFolderConflictTypes from "./VerticalEllipsisMenuFolderConflictTypes";
import CustomFieldsConfigModal from './CustomFieldsConfigModal';
import AlertBox from '../Common/AlertBox';
import CSLLoader from './../Common/CSLLoader';

const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 97%;
    margin-bottom: 25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

const MRModalLabel = styled.div`
	color: #1a3552;
	font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class CustomFields extends React.Component
{
	state = {
		custom_fields: null, 
		final_custom_field: null, 
		additional_fields: [], 
		show_custom_fields_modal: false, 
		curid: 0, 
		headerText: "", 
		content: null, 
		alert_param: null, 
		isLoadedIcon: false
	};

	componentDidMount()
	{		
		this.setState({
			custom_fields: this.props.custom_fields, 
			final_custom_field: this.props.custom_fields, 
			additional_fields: this.props.custom_fields.additional_fields
		});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {			
			this.setState({
				custom_fields: this.props.custom_fields, 
				final_custom_field: this.props.custom_fields, 
				additional_fields: this.props.custom_fields.additional_fields, 
				isLoadedIcon: false
			});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[key] = custom_fields[key] ? false : true;
		this.setState({custom_fields, final_custom_field: custom_fields});		
	}	

	handleToggleChange = (arg)=>{
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[arg] = custom_fields[arg] ? false : true;
		this.setState({custom_fields, final_custom_field: custom_fields});
		this.props.updateCustomFieldsToggle(custom_fields);
	}

	addAdditionalField = (event) => {
		event.preventDefault();
		console.log("addAdditionalField");			
		this.setState({
			show_custom_fields_modal: true, 
			curid: 0, 
			headerText: "Add"
		})	
	}

	closeModal = () => {		
		this.setState({show_custom_fields_modal: false})
    }

	updateCustomFieldsAll = (custom) => {
		console.log("custom",custom)
		this.setState({final_custom_field: custom})
	}

	updateCustomFieldsAllNew = (custom) => {
		console.log("custom",custom)
		this.setState({custom_fields: custom, final_custom_field: custom})
	}

	closePage = () => {
		this.props.closePage()
	}

	updateCustomModuleconfig = () => {
		console.log("sourav", this.state.final_custom_field)
		this.props.updateCustomFields(this.state.final_custom_field)
	}

	showConfigModal = (curid, isdelete) => (obj) => {
		console.log("curid==>", curid)
		console.log("obj==>", obj)
		if(obj === 'Edit'){			
            const content = this.state.additional_fields.find((item) => {
                return item.name === curid;
            })
            this.setState({content,curid: curid, show_custom_fields_modal: true, headerText: "Edit"});   
		}else if(obj === 'Delete'){
			console.log("Sourav")
            if(isdelete === 0){
				console.log("Sourav2")
                let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Custom Field from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteCustomField, stack: {id: curid}}
                this.setState({alert_param: alert_param})
            }else{
                let alert_param = {title: 'ERROR', message: 'This Custom has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.updateafternodelete, stack: {}}
                this.setState({alert_param: alert_param})
            }			
		}
	};

	updatedeleteCustomField = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        console.log("id",stack.id)           	
        let newreason = [];
        for(let i=0;i<this.state.additional_fields.length;i++)
        {
            if(this.state.additional_fields[i].name !== stack.id){
                newreason.push(this.state.additional_fields[i])
            }
        }			 
        console.log("delete===>",newreason); 
		this.setState({alert_param: null})  
        this.props.deleteCustomFieldsValue(newreason);
    }

	updateafternodelete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})        
    }

	handlenaSectionIncludedIsRequired = (section_id) => (event) => {
		console.log("content:", section_id);
		let {additional_fields} = this.state;
		let newadditional_fields_array = [];
		additional_fields.forEach((item) => {
			if (item.name === section_id) {
				item.required = item.required ? false : true; 				
			}
			newadditional_fields_array.push(item);
		})
		console.log("emails:", newadditional_fields_array);
        this.setState({isLoadedIcon: true})
		this.props.updateAdditional_fields(newadditional_fields_array);
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {additional_fields} = this.state;
		let newadditional_fields_array = [];
		additional_fields.forEach((item) => {
			if (item.name === section_id) {
				item.isActive = item.isActive === 1 ? 0 : 1; 				
			}
			newadditional_fields_array.push(item);
		})
		console.log("emails:", newadditional_fields_array);
        this.setState({isLoadedIcon: true})
		this.props.updateAdditional_fields(newadditional_fields_array);
	}

	tableData = () => {
        //console.log("this.state.additional_fields",this.state.additional_fields)
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'CUSTOM FIELD NAME', accessor: 'cname', minWidth: 150, headerStyle: {textAlign: 'left'}},					
                        {Header: 'TYPE', accessor: 'ctype', minWidth: 100, headerStyle: {textAlign: 'left'}},					
                        // {Header: 'DISPLAY', accessor: 'cdisplay', minWidth: 100, headerStyle: {textAlign: 'left'}},					
						{'Header' : 'REQUIRED', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								checked={row.original.isRequired ? true : false}
								icons={false}	  
								onChange={this.handlenaSectionIncludedIsRequired(row.original.id)}                              						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},	
						 {'Header' : 'STATUS', accessor: 'cstatus', Cell: row => (
							<div> 
							<Toggle
								style={{float:'left'}}
								checked={row.original.status === "Active" ? true : false}
								icons={false}	       
								onChange={this.handlenaSectionIncluded(row.original.id)}                         						
							/>
							</div>
						 ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}},						
                        {'Header' : '', accessor: 'cblnk', Cell: row => (
                            <div>                                
                                  <VerticalEllipsisMenuFolderConflictTypes editDelete={this.showConfigModal(row.original.id, row.original.isdelete)} options={["Edit", "Delete"]} />	
                            </div>
                         ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}}
                        ];

        let additional_fields_sort_array = this.state.additional_fields.sort(function(a,b) {return (a.nickname > b.nickname) ? 1 : ((b.nickname > a.nickname) ? -1 : 0);} );
		additional_fields_sort_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {
				id: item.name, 
				cname: item.nickname, 
				ctype: item.type, 
				// cdisplay: item.displayin, 
				isRequired: item.required, 
				status: statusValue, 
				isdelete: item.isDelete
			};
			ret.data.push(elem);
        })
        return ret;
    }   

	saveCustomFieldValue = (obj) => {
		this.setState({show_custom_fields_modal: false, isLoadedIcon: true})
		this.props.saveCustomFieldsValue(obj)
    }

	updateCustomFieldValue = (content) => {
		console.log("content:", content);		
		let {additional_fields} = this.state;
		let newreason = [];
		additional_fields.forEach((item) => {
			if (item.name === content.name) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_custom_fields_modal: false, isLoadedIcon: true})
		this.props.updateCustomFieldsValue(newreason);		
	}

	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.show_custom_fields_modal) {
							return (<div ><InactiveOverlay style={{height: "105%"}}/><CustomFieldsConfigModal headerText={this.state.headerText} data={this.state.custom_fields} closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} saveCustomFieldValue={this.saveCustomFieldValue} updateCustomFieldValue={this.updateCustomFieldValue}/></div>);
						}
					})()
				}
				{
					(() => {
						if(this.state.isLoadedIcon){
							return <div><CSLLoader style={{position: 'absolute'}}/></div>
						}
					})()
				} 
				<EmailContainer>
					<EmailInnerContainer>						
						<div style={{marginBottom:"10px", display: "block"}}>
							<MRModalLabel style={{display: "block", color: "#1d3957a8",marginBottom:"10px"}}>Enable Custom Fields?</MRModalLabel>							
							<Toggle					    
								icons={false}	
								onChange={()=> this.handleToggleChange('enabled')}										
								defaultChecked={this.state.custom_fields.enabled ? true : false} 								
							/>
						</div>						
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return(<div>
									<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
										Custom FIelds                              
										<div style={{float:'right'}}>
											<div onClick={this.addAdditionalField}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
												<FaPlusCircle  /> 
											</div>                            
											<div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
												<FaEllipsisV  /> 
											</div>
										</div>
									</EmailTopHeader>
									<CSLTable add={false} processData={this.tableData} tableRows={10} />
									</div>)
								}
							})()
						}						
					</EmailInnerContainer>
				</EmailContainer>
				<FooterButtonsContainer>   
					<CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
					<SaveBtn onClick={this.updateCustomModuleconfig}>Save</SaveBtn>
				</FooterButtonsContainer>
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default CustomFields;