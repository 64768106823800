import React from 'react';
import styled from 'styled-components';

import APICall from '../../Common/APICall';

const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 20px;
    background-color: #e7f0f7;    
    margin-bottom: 25px;
    color: #537386; 
    border: 1px solid #c4cfd6; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyTextHeader = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyText = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 10px;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 59px;
    height: 20px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;
class FollowupParentTask extends React.Component
{
    
    state = {
        ready: false,
        payload: {}
    };    

	componentDidMount()
	{
        const api = new APICall();
        let postData = {command: "fetchFollowupParentTask", taskId: this.props.parentTaskId, companyId: this.props.parentCompanyId, contactId: this.props.parentContactId};  
        api.command(postData, this.afterfetchTask); 
	}   

    afterfetchTask = (result) => {
            
        this.setState({payload: result, ready: true})
    }

    render()
    {        
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <MRModalBodyInner>
            <div style={{display: "inline-block", width: "100%"}}>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Conflicts ID</BodyTextHeader>
                    <BodyText>{this.state.payload.refId}</BodyText>
                </div>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Company</BodyTextHeader>
                    <BodyText>{this.state.payload.groupCompanyName}</BodyText>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Date of Entry</BodyTextHeader>
                    <BodyText>{this.state.payload.dateOfEntry}</BodyText>
                </div> 
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Discloser's Name</BodyTextHeader>
                    <BodyText>{this.state.payload.discloserName}</BodyText>
                </div>                                   
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Discloser's Email</BodyTextHeader>
                    <BodyText>{this.state.payload.discloserEmail}</BodyText>
                </div>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Date Conflict was raised</BodyTextHeader>
                    <BodyText>{this.state.payload.raisedDate}</BodyText>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Conflict Title</BodyTextHeader>
                    <BodyText>{this.state.payload.conflictTitle}</BodyText>
                </div>
                <div style={{display: "inline-block", width: "50%"}}>
                    <BodyTextHeader>Type</BodyTextHeader>
                    <BodyText>{this.state.payload.cType}</BodyText>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block"}}>
                    <BodyTextHeader>Nature of the Conflict</BodyTextHeader>
                    <div dangerouslySetInnerHTML={{__html: this.state.payload.natureOfConflict}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block"}}>
                    <BodyTextHeader>Describe the risk</BodyTextHeader>
                    <div dangerouslySetInnerHTML={{__html: this.state.payload.riskDescription}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block"}}>
                    <BodyTextHeader>Describe the steps being taken to mitigate this risk</BodyTextHeader>
                    <div dangerouslySetInnerHTML={{__html: this.state.payload.stepsToMitigateRisk}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                </div>
            </div>
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block"}}> 
                    <BodyTextHeader>Are there any additional records or information relating to this Disclosure?</BodyTextHeader>
                    <div className="switch">
                        <input type="radio" name="allowAROD" id="yes" value="Yes" checked={this.state.payload.additionalRecordsDisclosure.enabled === 'Yes'}  />
                        <Radiolevel className="rediolabel" htmlFor="yes" style={{cursor: "default"}}>Yes</Radiolevel>
                        <input type="radio" name="allowAROD" id="no" value="No" checked={this.state.payload.additionalRecordsDisclosure.enabled === 'No'}  />
                        <Radiolevel className="rediolabel" htmlFor="no" style={{cursor: "default"}}>No</Radiolevel>
                        <span className="switchFilter"></span> 
                    </div>
                </div>
            </div>
            {
                (() => {
                    if(this.state.payload.additionalRecordsDisclosure.enabled === 'Yes'){
                        return(<div style={{display: "inline-block", width: "100%"}}>
                        <div style={{display: "inline-block"}}>                                        
                            <div dangerouslySetInnerHTML={{__html: this.state.payload.additionalRecordsDisclosure.description}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                        </div>
                    </div>)
                    }
                })()
            }
            <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                <div style={{display: "inline-block"}}> 
                    <BodyTextHeader>Has or will a Disclosure be made to a 3rd party?</BodyTextHeader>
                    <div className="switch">
                        <input type="radio" name="allowThiredParty" id="yes" value="Yes" checked={this.state.payload.disclosureToThirdParty.enabled === 'Yes'}  />
                        <Radiolevel className="rediolabel" htmlFor="yes" style={{cursor: "default"}}>Yes</Radiolevel>
                        <input type="radio" name="allowThiredParty" id="no" value="No" checked={this.state.payload.disclosureToThirdParty.enabled === 'No'}  />
                        <Radiolevel className="rediolabel" htmlFor="no" style={{cursor: "default"}}>No</Radiolevel>
                        <span className="switchFilter"></span> 
                    </div>
                </div>
            </div>
            {
                (() => {
                    if(this.state.payload.disclosureToThirdParty.enabled === 'Yes'){
                        return(<div style={{display: "inline-block", width: "100%"}}>
                        <div style={{display: "inline-block"}}>                                        
                            <div dangerouslySetInnerHTML={{__html: this.state.payload.disclosureToThirdParty.description}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                        </div>
                    </div>)
                    }
                })()
            }                         
        </MRModalBodyInner>
        )
    }

}
export default FollowupParentTask;