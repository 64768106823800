import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import * as Style from '../StyledComponents';
import {FaAngleRight} from 'react-icons/fa';

const SideButton_UnSelected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    margin-bottom: 4px;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;
const SideButton_Selected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    font-weight: bold;
    margin-bottom: 4px;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;

class SideAuditlogMenuItems extends React.Component
{
	state = { 
				cur_view: null, 			
		        screen:{changelog : 0, activitylog : 1, emaillog : 0},
			}

    handleButton =(event) =>{
        event.preventDefault();
        var screen = this.state.screen;
        var current_view = null;
        screen.changelog = 0;
        screen.activitylog = 0;
        screen.emaillog = 0;
        if([event.target.id] == 'changelog'){
            screen.changelog = 1;
            current_view = 'changelog';
        }
        if([event.target.id] == 'activitylog'){
            screen.activitylog = 1;
            current_view = 'activitylog';
        }
        if([event.target.id] == 'emaillog'){
            screen.emaillog = 1;
            current_view = 'emaillog'
        }      
        this.props.refreshParrentCallback(current_view) 
        this.setState({screen : screen}) 
    }

	render()
	{
		return (
            <div style={{display:"inline-block" , width:"15%", height:"900px"}}>
               <div style={{position:"absolute"}}>
                <div style={{padding: "0px 15px 15px 10px"}}>
                    {
                        (() =>{
                              if(this.state.screen.changelog === 1){
                                return <SideButton_Selected id = "changelog" onClick={this.handleButton} >Change Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "changelog" onClick={this.handleButton} >Change Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                        })()
                    }
                    {
                        (() =>{
                              if(this.state.screen.activitylog === 1){
                                return <SideButton_Selected id = "activitylog" onClick={this.handleButton} >Activity Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "activitylog" onClick={this.handleButton} >Activity Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                        })() 
                    }
                    {
                        (() =>{
                              if(this.state.screen.emaillog === 1){
                                return <SideButton_Selected id = "emaillog" onClick={this.handleButton} >Email Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "emaillog" onClick={this.handleButton} >Email Log <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                        })()  
                    }                                             
                </div>
               </div>
            </div>


		);
	}
}

export default SideAuditlogMenuItems;