import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

let options = [];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    //console.log(event.currentTarget);
  };
  const [Options, setOptions] = useState([]);
  useEffect(() => {
    options = ["Edit", "Delete"];    
    setOptions(options);
  }, []);


  const handleClose = (option, index) => {
    console.log("option==>", option)
    console.log("index==>", index)
    setAnchorEl(null);
    props.editDelete(option)
    //   if (index == 0) {
    //     props.viewdoc();
    //   } else if (index == 1) {
    //     DeleteDocs();
    //   }
  };
  const DeleteDocs = (event) => {    
    props.DeleteDocs("deletedocs");
  };


  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px"}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {Options.map((option, index) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleClose(option, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}