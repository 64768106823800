import React from 'react';
import styled from 'styled-components';
import CSLTable from '../../Common/CSLTable';
import { FaEdit,FaPencilAlt, FaTimes,FaPlusCircle } from "react-icons/fa";
import RemovalConfigModal from './RemovalConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";
import Store from '../../Common/Store';
import AlertBox from '../Common/AlertBox';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 100%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const EmailContainer = styled.div`
	width: 100%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
class General extends React.Component
{
	state = {general: null,reason_for_removal: null,reason_for_removal_search: null, 
		show_reason_for_removal_array: null, show_removal_config_modal: false,curid: 0,content: null,
		showActiveCheckbox: false, reasonActiveArrayAll: null,reasonInactiveArrayAll: null,
		alert_param: null};

	componentDidMount()
	{		
		let reasonActiveArrayAll = [];		
		let reasonInactiveArrayAll = [];
		for(let i=0;i<this.props.reason_for_removal.length;i++){
			if(this.props.reason_for_removal[i].isActive === 1){
				reasonActiveArrayAll.push(this.props.reason_for_removal[i])
			}else{
				reasonInactiveArrayAll.push(this.props.reason_for_removal[i])
			}
		}
		let reasonValue = [];
		if(this.props.general.reason_active_inactive){
			reasonValue = reasonInactiveArrayAll
			console.log("Sourav")
		}else{			
			reasonValue = reasonActiveArrayAll
			console.log("Sourav1")
		}
		this.setState({general: this.props.general,
			showActiveCheckbox: this.props.general.reason_active_inactive,
			reason_for_removal: this.props.reason_for_removal,
			show_reason_for_removal_array: reasonValue,
			reason_for_removal_search: this.props.reason_for_removal,
			reasonActiveArrayAll: reasonActiveArrayAll,
			reasonInactiveArrayAll: reasonInactiveArrayAll});
		console.log("reason_for_removal===>",this.props.reason_for_removal);
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {

			let reasonActiveArrayAll = [];		
			let reasonInactiveArrayAll = [];
			for(let i=0;i<this.props.reason_for_removal.length;i++){
				if(this.props.reason_for_removal[i].isActive === 1){
					reasonActiveArrayAll.push(this.props.reason_for_removal[i])
				}else{
					reasonInactiveArrayAll.push(this.props.reason_for_removal[i])
				}
			}
			let reasonValue = [];
			if(this.props.general.reason_active_inactive){
				reasonValue = reasonInactiveArrayAll
				console.log("Sourav")
			}else{			
				reasonValue = reasonActiveArrayAll
				console.log("Sourav1")
			}
			this.setState({general: this.props.general,
				showActiveCheckbox: this.props.general.reason_active_inactive,
				reason_for_removal: this.props.reason_for_removal,
				show_reason_for_removal_array: reasonValue,
				reason_for_removal_search: this.props.reason_for_removal,
				reasonActiveArrayAll: reasonActiveArrayAll,
				reasonInactiveArrayAll: reasonInactiveArrayAll});
			console.log("reason_for_removal===>",this.props.reason_for_removal);
			// this.setState({general: this.props.general,reason_for_removal: this.props.reason_for_removal, reason_for_removal_search: this.props.reason_for_removal});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}
	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', minWidth: 180, headerStyle: {textAlign: 'left'}}, 
						//{Header: 'Req. Description', accessor: 'req_description', minWidth: 140, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Req. Description', accessor: 'descriptionreq', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.req_description === 1) {
											   return(<div style={{fontSize:"21px", float: "left"}} ><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{fontSize:"21px", float: "left"}} ><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
						{Header: 'Status', accessor: 'status', minWidth: 80, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Action', accessor: 'action', Cell: row => (
							<div>
								<FaPencilAlt  onClick={this.openModal(row.original.id)} style={{cursor: 'pointer',fontSize: '17px',color: '#b4b5b4',display :'inline-block',marginRight: "5px"}} /> 
								{
									(() => {
										if(row.original.isdelete === 0){
											return(<FaTimes onClick={this.deleteReason(row.original.id)} style={{cursor: 'pointer',fontSize: '18px',color: '#b4b5b4',display :'inline-block'}}/>)
										}else{
											return(<FaTimes onClick={this.notDeleteReason} style={{cursor: 'pointer',fontSize: '18px',color: '#b4b5b4',display :'inline-block'}}/>)
										}
									})()
								}
								
							</div>
						  ), width: 55, headerStyle: {textAlign: 'center'}}, 
						];
		this.state.show_reason_for_removal_array.forEach((item) => {
			let statusValue = item.isActive === 1 ? "Active" : "Inactive"
			let elem = {id: item.reasonID, name: item.reasonName, req_description: item.isDescription, status: statusValue, isdelete: item.isDelete};
			ret.data.push(elem);
		})        
        return ret;
	}

	notDeleteReason = () => {
		// alert("This reason has already been used.")
		let alert_param = {title: 'Alert', message: 'This reason has already been used.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.afterClickOkButton, stack: {}}
        this.setState({alert_param: alert_param})
	}

	afterClickOkButton = (result, stack) => {        
        this.setState({alert_param: null})        
    }

	deleteReason = (id) => (event) => {
		let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this Reason from the list?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
                           alertHandler: this.updatedeleteReason, stack: {id: id}}
        this.setState({alert_param: alert_param})
        // if (window.confirm('Are you sure you want to delete this Reason from the list?')) {
        //    	console.log("id",id)
        //    	let {reason_for_removal} = this.state;
		// 	let newreason = [];
		// 	for(let i=0;i<this.state.reason_for_removal.length;i++)
		// 	{
		// 		if(this.state.reason_for_removal[i].reasonID !== id){
		// 			newreason.push(this.state.reason_for_removal[i])
		// 		}
		// 	}			 
		// 	console.log("delete===>",newreason);   
		// 	this.props.deleteReasonValue(newreason);        
		// }         		  
    }

	updatedeleteReason = (result, stack) => {  
        if(!result) {
            this.setState({alert_param: null})
            return
        }
        this.setState({alert_param: null})        
        console.log("id",stack.id)     
		let newreason = [];
		for(let i=0;i<this.state.reason_for_removal.length;i++)
		{
			if(this.state.reason_for_removal[i].reasonID !== stack.id){
				newreason.push(this.state.reason_for_removal[i])
			}
		}			 
		console.log("delete===>",newreason);   
		this.props.deleteReasonValue(newreason);           
    }    

	openModal = (id) => (event) => {
		event.preventDefault();     
		console.log("id",id); 
		const content = this.state.reason_for_removal.find((item) => {
			return item.reasonID === id;
		})
		this.setState({content,curid: id,show_reason_config_modal: true});        
	}
	
	addReasonConfigModal = (event) => {
		event.preventDefault();
		this.setState({show_reason_config_modal: true,curid: 0})
	}

	closeModal = () => {		
		this.setState({show_reason_config_modal: false})
	}

	saveReasonDate = (obj) => {
		this.setState({show_reason_config_modal: false})
		this.props.saveReasonValue(obj)
	}

	modifyReason = (content) => {
		console.log("content:", content);
		//console.log("this.state",this.state.reason_for_removal)
		let {reason_for_removal} = this.state;
		let newreason = [];
		reason_for_removal.forEach((item) => {
			if (item.reasonID === content.reasonID) {
				item = content;
				console.log("matched", item);
			}
			newreason.push(item);
		})
		console.log("resons:", newreason);
		this.setState({show_reason_config_modal: false})
		this.props.updateReason(newreason);
		// this.setState({email: newemail, show_email_config_modal: null});
	}

	searchValue = (event) => {
		event.preventDefault();
        console.log("Sourav",event.target.value);
		console.log("fddsf",this.state.reason_for_removal_search)
		if(!this.state.showActiveCheckbox){
			let search_string = event.target.value;
			if (search_string === "") {
				let reason_for_removal = this.state.reasonActiveArrayAll;            
				this.setState({show_reason_for_removal_array: reason_for_removal});
			}else {
				let all_value = this.state.reasonActiveArrayAll;
				let filtered_values = [];
				all_value.forEach((reason) => {
					if (reason.reasonName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(reason);
					}                
				})            
				let reason_for_removal = filtered_values;

				this.setState({show_reason_for_removal_array: reason_for_removal})
			}
		}else{
			let search_string = event.target.value;
			if (search_string === "") {
				let reason_for_removal = this.state.reasonInactiveArrayAll;            
				this.setState({show_reason_for_removal_array: reason_for_removal});
			}else {
				let all_value = this.state.reasonInactiveArrayAll;
				let filtered_values = [];
				all_value.forEach((reason) => {
					if (reason.reasonName.toLowerCase().includes(search_string.toLowerCase())) {
						filtered_values.push(reason);
					}                
				})            
				let reason_for_removal = filtered_values;

				this.setState({show_reason_for_removal_array: reason_for_removal})
			}
		}
	}
	
	handleActiveInactive = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
		let data = value === 1 ? true : false;
		this.props.changeActiveInactive(data);		       
    }

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div>
			{
				(() => {
					if (this.state.show_reason_config_modal) {
						return (<RemovalConfigModal closeModal={this.closeModal} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} updateReasonValue={this.modifyReason} saveReasonValue={this.saveReasonDate}/>);
					}
				})()
			}
			<GeneralContainer>
				<GeneralInnerContainer>
					{
						(() => {
							if(Store.getStoreData('has_gc')){
								return(<div>
									<GeneralLabel>Show Parent Company Conflicts</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.show_parent_company_gifts) {
												return (
													<RadioBlock>
														<SelectedRadio>Yes</SelectedRadio>
														<UnselectedRadio onClick={this.switchRadio('show_parent_company_gifts')}>No</UnselectedRadio>
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio onClick={this.switchRadio('show_parent_company_gifts')}>Yes</UnselectedRadio>
														<SelectedRadio>No</SelectedRadio>
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}else{
								return(<div>
									<GeneralLabel style={{color: "#bfbfbf"}}>Show Parent Company Conflicts</GeneralLabel>
									{
										
										(() => {
											if (this.state.general.show_parent_company_gifts) {
												return (
													<RadioBlock>
														<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</SelectedRadio>
														<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>No</UnselectedRadio>
													</RadioBlock>
												);
											} else {
												return (
													<RadioBlock>
														<UnselectedRadio style={{color: "#9c9c9c",border: "1px solid #bfbfbf",cursor: "default"}}>Yes</UnselectedRadio>
														<SelectedRadio style={{backgroundColor: "#72b9b5",border: "1px solid #bfbfbf",cursor: "default"}}>No</SelectedRadio>
													</RadioBlock>
												);
											}
										})()
									}
								</div>)
							}
						})()
					}
					<GeneralLabel>Record Conflict Owner</GeneralLabel>
					{
						(() => {
							if (this.state.general.record_conflict_owner) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('record_conflict_owner')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('record_conflict_owner')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					{
						(() => {
							if(this.state.general.record_conflict_owner){
								return(<div>
										<GeneralLabel>Allow Conflict Owners to update Conflicts</GeneralLabel>
										{
											(() => {
												if (this.state.general.conflict_owners_update) {
													return (
														<RadioBlock>
															<SelectedRadio>Yes</SelectedRadio>
															<UnselectedRadio onClick={this.switchRadio('conflict_owners_update')}>No</UnselectedRadio>
														</RadioBlock>
													);
												} else {
													return (
														<RadioBlock>
															<UnselectedRadio onClick={this.switchRadio('conflict_owners_update')}>Yes</UnselectedRadio>
															<SelectedRadio>No</SelectedRadio>
														</RadioBlock>
													);
												}
											})()
										}
								</div>)
							}
						})()
					}
					<GeneralLabel>Change Status Terminology</GeneralLabel>
					{
						(() => {
							if (this.state.general.chg_staus_termilogy) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('chg_staus_termilogy')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('chg_staus_termilogy')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Require Reason for Removal</GeneralLabel>
					{
						(() => {
							if (this.state.general.require_reason_for_removal) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('require_reason_for_removal')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('require_reason_for_removal')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}					
				</GeneralInnerContainer>
				{
					(() => {
						if (this.state.general.require_reason_for_removal) {
							return(
							<div>
								<div>
									<SearchInput style={{width: "98.5%",marginTop: "15px"}} placeholder="Search..." onChange={this.searchValue} />
								</div>
								<MRModalLabel>Show Inactive?</MRModalLabel>
								{
									(() => {
										if(!this.state.showActiveCheckbox){
											return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(1)} ><IoIosSquareOutline /></div>)
										}else{
											return(<div  style={{cursor: "pointer", fontSize:"21px",display: "inline-block",marginLeft: "5px"}} onClick={this.handleActiveInactive(0)} ><IoIosCheckboxOutline /></div>)
										}
									})()
								}
								<EmailContainer>
									<EmailInnerContainer>
										<div style={{display: "flow-root"}}>
											<div style={{float: "left"}}>
												<EmailBoldLabel style={{display: "inline-block"}}>Configure Options</EmailBoldLabel>
											</div>
											<div onClick={this.addReasonConfigModal} style={{float: "right", cursor: "pointer",fontSize: "17px",color: "#04ADA8"}}>
												<FaPlusCircle />
											</div>	
										</div>									
										<CSLTable add={false} processData={this.tableData} headerText={''} tableRows="8" refreshCallback={this.refreshState} />
									</EmailInnerContainer>
								</EmailContainer>
							</div>
							);
						}
					})()
				}
								
			</GeneralContainer>
			<AlertBox alertParam = {this.state.alert_param}/> 
			</div>
		);
	}
}

export default General;