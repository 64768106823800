import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import * as Style from '../StyledComponents';
import APICall from '../../Common/APICall.js';
import WelcomeBanner from '../WelcomeBanner'
import SideAuditlogMenuItems from './SideAuditlogMenuItems'
import ActivityLog from './ActivityLog.js'
import EmailLog from './EmailLog.js'
import DataChangeLog from './DataChangeLog'

class AuditlogsIndex extends React.Component
{
	state = {  
               ready: false,
               view_count: 0, 
               cur_view: 'activitylog',     
		           screen:{changelog : 0, activitylog : 1, emaillog : 0},
               module:{},
               contact_name :'',
               menu_obj : '',
               activitylog_data :{},
               contact_list : {},
               gc_companies : {},
               client_name : '',
               emaillog : {},
               data_change_log: {}
			}

	componentDidMount = () => {
    console.log("componentDidMount_Auditlogs")
        let postData = { command: "getauditlog" };
        let api = new APICall();
        api.command(postData, this.processData);
    }

    processData = (result) => {
        console.log("Result3333====>",result)
        this.setState({
                ready: true, 
                contact_name :result.loggedin_contact.contact_name,
                activitylog_data : result.my_tasks,
                contact_list : result.contact_list,
                gc_companies : result.gc_companies,
                client_name : result.clientname,
                emaillog : result.emaillog,
                data_change_log : result.data_change_log
            });
    }
    refreshCallBack = (cur_view) => {
        this.setState({cur_view : cur_view})
    }

	render()
	{
        //console.log("state", this.state.client_name)
		if(!this.state.ready) {
            return(<div>Loading...</div>);
        }
		return (

            <div>
                <WelcomeBanner welcome={this.state.contact_name}/> 
                <div style={{marginTop: "40px", width : "100%", position: "relative"}}>
                    <SideAuditlogMenuItems refreshParrentCallback ={ this.refreshCallBack} />
                    {
                        (() =>{
                            switch(this.state.cur_view) {
                                case 'activitylog' : return <ActivityLog activitylog_data = {this.state.activitylog_data} contact_list = {this.state.contact_list} gc_companies = {this.state.gc_companies} client_name = {this.state.client_name}/>; break;
                                //case 'emaillog' : return <EmailLog emaillog_data = {this.state.emaillog}/>
                                case 'changelog' : return <DataChangeLog data_change_log = {this.state.data_change_log} contact_list = {this.state.contact_list} gc_companies = {this.state.gc_companies} client_name = {this.state.client_name}/>
                                default: return <Style.NoPermission>Comming Soon...</Style.NoPermission>;
                            }
                        })()
                    }
                </div>
            </div>
		  	
		);
	}
}

export default AuditlogsIndex;