import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import Dropzone from '../Common/Dropzone';
import { FaTimes} from 'react-icons/fa';
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import moment from 'moment';
import FollowupParentTask from './FollowupParentTask';
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 98%;
    position: absolute;
    margin: 0 0;
    top: 15%;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    margin-left: 10px;
`;
const MRModalHeader = styled.div`
    background-color: #F6F9FD;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 90px;    
    width: calc(100% - 0px);
    border-width: 1px 1px 1px 10px; 
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 30px;
    margin-left: 35px;  
    color: #1A3552;  
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 26px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const ScoreBodyContainer = styled.div`
    padding: 10px;
    text-align: left;
    overflow-y: scroll;
    height: 555px;
`;
const ModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: #F3F7FB;
    padding: 10px;
    border-radius: 4px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 20px;
    background-color: #e7f0f7;    
    margin-bottom: 25px;
    color: #537386; 
    border: 1px solid #c4cfd6; 
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyTextHeader = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
`;
const BodyText = styled.div`     
    color: #537386;     
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 500;
    margin-top: 10px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalSaveCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #758597;
    border: 1px solid #dadada;
    width: 94px;
    height: 43px;
    border-radius: 4px;
    font-size: 12px;
    float: right;
    cursor: pointer;
    font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
    font-weight: 500;
`;
const DismissalReason = styled.textarea`
    height: 130px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #444444;
    font-weight: 100;   
    font-family: 'Montserrat',sans-serif;
`;
const ModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const AlwaysScrollToView = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};
class FollowupModal extends React.Component
{
    
    state = {task: null, payload: {}, task_id: null, comments: "",task_title:"", task_note: "", ready: false, send_mail_config: false,
    curlane: '',binFiles: [], cur_files_json: null, is_disabled: false, isLoadedIcon: false, alert_param: null, taskTitle:"", dueDateValue: '-', parentTaskId: 0, parentCompanyId: 0, parentContactId: 0};

    GetPayloadData = (parent_task) => {
        let payload = {};
        let parent_task_json = JSON.parse(parent_task.task_json);
        payload.Ref_ID = parent_task_json.object_data.ref_id;
        payload.nature_of_conflict = parent_task_json.object_data.headerinfo.nature_of_conflict
        return payload;
    }

	componentDidMount()
	{
        let {payload,task_id,task_title,task_note,curlane} = this.state;
        let task_data = Store.getStoreData('followup_task');
        curlane = task_data.subtask.cur_lane;
        let followup_task = task_data.subtask;
        task_id = followup_task.id;
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject
        task_note = task_json.object_data.note
        let cur_files_json = [];
        if('bin_files' in task_json.object_data){
            cur_files_json = task_json.object_data.bin_files;
        }
        let task_command = "";
        if('commands' in task_json.object_data){
            task_command = task_json.object_data.commands;
        }
        let parent_task = task_data.parenttask;
        console.log("parent_task",followup_task)
        payload = this.GetPayloadData(parent_task);
        console.log("payload", task_data);
        let taskTitle = task_json.object_data.subject;
        let dueDate = moment(followup_task.due_date).unix();
        let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
        let parentTaskId = parent_task.id;
        let parentCompanyId = parent_task.company_id;
        let parentContactId = parent_task.contact_id;
        this.setState({task: task_json,payload,task_id,task_title,task_note,comments: task_command,curlane,cur_files_json: cur_files_json,ready:true,taskTitle,dueDateValue,parentTaskId,parentCompanyId,parentContactId});        
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {payload,task_id,task_title,task_note,curlane} = this.state;
            let task_data = Store.getStoreData('followup_task');
            curlane = task_data.subtask.cur_lane;
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject
            task_note = task_json.object_data.note
            let parent_task = task_data.parenttask;
            console.log("parent_task",parent_task)
            payload = this.GetPayloadData(parent_task);
            console.log("payload", task_data);
            let taskTitle = task_json.object_data.subject;
            let dueDate = moment(followup_task.due_date).unix();
            let dueDateValue = moment.unix(dueDate).format('DD/MM/YYYY')
            this.setState({task: task_json,payload,task_id,task_title,task_note,curlane,ready:true,taskTitle,dueDateValue});
		}
	}
    closeModal = (event) => {
        // if (document.referrer.endsWith("/tmview")) {
        //     window.location = '/select/microservice/mstop/tmview';
        // } else {            
        //     this.props.closeModal();
        // }
        if(Store.getStoreData('isreferenceUrl') === true){
            // window.location = '/select/microservice/mstop/tmview';
            this.props.closeModal();
        } else {
            this.props.closeModal();
        }
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        //console.log(task_json);
        task_json['task_data'].last_action = 'COMP_COI_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_COI_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;
        task_json['action_data'] = {action: "COMP_COI_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('contact_id'), cur_lane: "COMP_COI_FOLLOWUP_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        let postData = {command: "update_conflicts_followup_task", task_json: task_json, task_id: this.state.task_id };
        console.log(postData);
        const api = new APICall();
        this.setState({isLoadedIcon: true})
        api.command(postData, this.afterCompleted);
    }
    afterCompleted = (result) => {
        // console.log("Completed:", result);
        // alert('Followup task completed successfully.');
        let alert_param = {title: 'Success', message: 'Followup task completed successfully.', ok_text: 'Ok', confirm: false, 
                alertHandler: this.okAfterComplete, stack: {}}
        this.setState({alert_param: alert_param, isLoadedIcon: false})
        // window.location.reload(true);
    }
    okAfterComplete = (result, stack) => {        
        this.setState({alert_param: null})
        window.location.reload();
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
            
        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    render()
    {
        let curAssignTo = Store.getStoreData('followup_task').subtask.cur_assigned_to;
        let curUserID = Store.getStoreData('contact_id');
        let role = Store.getStoreData('role');
        console.log("task_data===>", role)
        if(!this.state.ready){
            return <div> Loading.....</div>
        }
        return(
            <div>
                {
                    (() => {
                        if(this.state.isLoadedIcon){
                            return <div><CSLLoader style={{position: 'absolute'}}/></div>
                        }
                    })()
                }
                <MRModalContainer>
                    <AlwaysScrollToView />
                    <MRModalHeader>                        
                        <MRModalHeaderText>Follow-up Task</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <ScoreBodyContainer>
                        <ModalBody style={{paddingLeft: "25px", paddingRight: "25px"}}>
                            <FollowupParentTask parentTaskId={this.state.parentTaskId} parentCompanyId={this.state.parentCompanyId} parentContactId={this.state.parentContactId}/>
                            <MRModalBodyInner>
                                <div style={{display: "inline-block", width: "100%"}}>
                                    <div style={{display: "inline-block", width: "100%"}}>
                                        <BodyTextHeader>Subject</BodyTextHeader>
                                        <BodyText>{this.state.taskTitle}</BodyText>
                                    </div>
                                </div>
                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                    <div style={{display: "inline-block", width: "100%"}}>
                                        <BodyTextHeader>Due Date</BodyTextHeader>
                                        <BodyText>{this.state.dueDateValue}</BodyText>
                                    </div>
                                </div>
                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                    <div style={{display: "inline-block"}}>
                                        <BodyTextHeader>Description</BodyTextHeader>
                                        <div dangerouslySetInnerHTML={{__html: this.state.task_note}} style={{textAlign: "justify", fontFamily: "'Montserrat', sans-serif", color: "#537386",fontWeight: "500", marginTop: "10px"}}></div>
                                    </div>
                                </div>
                                {
                                    (() => {
                                        if(this.state.cur_files_json !== null && this.state.cur_files_json.length !== 0){
                                            return(
                                                <div style={{display: "inline-block", width: "100%", marginTop: "20px"}}>
                                                    <div style={{display: "inline-block", width: "100%"}}>
                                                        <BodyTextHeader>Attachments</BodyTextHeader>
                                                        <BodyText><Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={false}/></BodyText>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()
                                }
                            </MRModalBodyInner>
                            {
                                (() => {
                                    if(curAssignTo === curUserID && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                        return(
                                            <div>
                                                <ModalLabel>Comments</ModalLabel>
                                                <DismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></DismissalReason>
                                            </div>
                                        )
                                    }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                        return(
                                            <div>
                                                <ModalLabel>Comments</ModalLabel>
                                                <DismissalReason name="comments" onChange={this.handleChange} value={this.state.comments}></DismissalReason>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div>
                                                <ModalLabel>Comments</ModalLabel>
                                                <DismissalReason name="comments" value={this.state.comments} readOnly></DismissalReason>
                                            </div>
                                        )
                                    }
                                })()
                            }
                        </ModalBody>
                    </ScoreBodyContainer>
                    <MRModalFooter>
                        <div style={{position: "relative",padding: "17px"}}>  
                            {
                                (() => {
                                    if(curAssignTo === curUserID && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                        return(<MRModalSaveCloseBtn onClick={this.UpdateSubTask}>COMPLETE</MRModalSaveCloseBtn> )
                                    }else if((role === "admin_manager" || role === "manager") && this.state.curlane !== "COMP_COI_FOLLOWUP_COMPLETE"){
                                        return(<MRModalSaveCloseBtn onClick={this.UpdateSubTask}>COMPLETE</MRModalSaveCloseBtn> )
                                    }
                                })()
                            }                          
                            <MRModalCancelBtn onClick={this.closeModal}>CLOSE</MRModalCancelBtn>
                        </div>
                    </MRModalFooter>
                </MRModalContainer>            
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>
        )
    }

}
export default FollowupModal;