import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge,FaWrench } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faHouseBlank, faSquareList} from "@fortawesome/pro-thin-svg-icons";
import Store from '../Common/Store'
import {Link} from "react-router-dom";
const HeadContainer = styled.div`
    display: block;    
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 7px;
    font-size: 16px;
    color: #282828;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 7px;
    color: #282828;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: black;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;
class CSLHeader extends React.Component
{
    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
        let site_layout = JSON.parse(localStorage.getItem('site_layout'));
        use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        const role = Store.getStoreData('role')
        return (
            <HeadContainer>
                <Link to={'/'}><HomeText><FontAwesomeIcon icon={faHouseBlank} style={{marginRight: '7px'}} /><span style={{fontWeight: "600"}}>{this.props.headerText}</span></HomeText></Link>
                { role === 'admin_manager' &&
                    <Link to={'/moduleconfig'}><Categories title='Module Config'><FontAwesomeIcon icon={faGear}/></Categories></Link>
                }
                { role === 'admin_manager' &&
                    <a href={process.env.REACT_APP_AUDIT_URL}><Categories title='Audit Logs'><FontAwesomeIcon icon={faSquareList}/></Categories></a>
                }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default CSLHeader;