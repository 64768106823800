import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaTrashAlt, FaQuestionCircle} from 'react-icons/fa';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 70%;
	position: absolute;
	background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1000;
	padding: 20px;
`;

const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 0px;
`;

const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #282828;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const RegModalNameInput = styled.input`
    height: 28px;
    width: 97%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    background-color: #ffffff;
    font-family: 'Montserrat',sans-serif;
`;
const MRModalTextarea = styled.textarea`
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #000000;
    font-family: 'Montserrat',sans-serif;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
    font-family: 'Montserrat',sans-serif;
`;  
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 20%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
`;

class EditModal extends React.Component
{
    state = {
        conflictType: "",
        descriptions: "",
        status: 1,
        curid: null,
        isComments: 0
    }

    closeView = () => {
        this.props.closeModal();
    }
    componentDidMount()
	{
        if(this.props.curid !== 0) {
            console.log(this.props.client_conflict_types[this.props.curid])
            let current_conflict_type=this.props.client_conflict_types[this.props.curid];
            this.setState({conflictType: current_conflict_type.title, descriptions: current_conflict_type.description,
            status: current_conflict_type.isActive, isDelete: current_conflict_type.isDelete, isComments: current_conflict_type.isComments})
        }else{
            let randNo = Math.floor(Math.random()*(999-100+1)+100);
            console.log("randno",randNo);
            this.setState({curid: randNo});
        }
    }
    
    handleChange = (evt) => {        
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleisDescription = (value) => (event) => {
        //event.preventDefault();
        console.log("value",value)  
        let data = value === 1 ? 0 : 1;      
        this.setState({isComments: data})        
    }

    submitConflict = (event) => {
        event.preventDefault();
        if(this.state.conflictType === "")
        {
            alert("Please provide a conflict type name.");
        }else {
            //console.log(JSON.parse(this.state.status))
            if(this.props.curid !== 0) {
                this.props.saveConflictValue(this.props.curid,this.state.conflictType,this.state.descriptions,parseInt(this.state.status),parseInt(this.state.isDelete),0, parseInt(this.state.isComments));
            }else{
                this.props.saveConflictValue(this.state.curid,this.state.conflictType,this.state.descriptions,this.state.status,0,1, parseInt(this.state.isComments));
            }
        }
    }

    render() {
        return (
            <EmailContainer>
                <MRModalHeader>
                <MRModalHeaderText>Conflict Type Details</MRModalHeaderText>
                <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>  
                </MRModalHeader>
                <MRModalLabel style={{marginTop: "50px"}}>Conflict Type</MRModalLabel>
                <RegModalNameInput name="conflictType" value={this.state.conflictType} onChange={this.handleChange}/>
                <MRModalLabel style={{marginTop: "30px"}}>Description</MRModalLabel> 
                <MRModalTextarea rows="5" style={{maxWidth: "97%"}} name="descriptions" value={this.state.descriptions} onChange={this.handleChange}/> 
                {
                    (() => {
                        if(this.state.isComments === 1){
                            return(<div style={{display: "flex"}}>
                                <div style={{cursor: "pointer", fontSize:"21px", display: "inline-block", marginTop: "10px"}} onClick={this.handleisDescription(1)}>
                                    <IoIosCheckboxOutline />
                                </div>
                                <div style={{display: "inline-block"}}>
                                    <MRModalLabel style={{marginLeft: "10px", marginTop: "12px"}}>Require Comments when selected</MRModalLabel>
                                </div>
                            </div>)
                        }else{
                            return(<div style={{display: "flex"}}>
                                <div style={{cursor: "pointer", fontSize:"21px", display: "inline-block", marginTop: "10px"}} onClick={this.handleisDescription(0)}>
                                    <IoIosSquareOutline />
                                </div>
                                <div style={{display: "inline-block"}}>
                                    <MRModalLabel style={{marginLeft: "10px", marginTop: "12px"}}>Require Comments when selected</MRModalLabel>
                                </div>
                            </div>)
                        }
                    })()
                } 
                <MRModalLabel style={{marginTop: "30px"}}>Status</MRModalLabel>
                <MRModalSelect onChange={this.handleChange} name="status" value={this.state.status}>
                    <option  value="1">Active</option>
                    <option  value="0">Inactive</option>   
                </MRModalSelect>
                <div style={{float: "right"}}>
                    <SaveBtn onClick={this.submitConflict}>Submit</SaveBtn>
                </div>
            </EmailContainer>
        )
    }
}

export default EditModal;