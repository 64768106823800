import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import * as Style from '../StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from '../../Common/CSLTable.js'
import APICall from '../../Common/APICall.js';
import ActivityCompletedModal from './ActivityCompletedModal'
import DatePicker from "react-date-picker";
//import "react-datepicker/dist/react-datepicker.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;
const Source_Map = {conflicts: 'Conflict Register'}
const Activity_Shown = {COMP_CONFLICTS_REGISTER: 1, COMP_CONFLICTS_COMPLETE: 1};
const Activity_Text = {'COMP_CONFLICTS_REGISTER': 'Conflict Assigned', 'COMP_CONFLICTS_COMPLETE': 'Conflicts Completed'};
class ActivityLog extends React.Component
{
	state = { 
                activitylog_data : {},
                client_name : '',
                modal_view : null,
                modal_data : {},
                ddlmodule_val : 0,
                ddldate_val : 'anydate',
                startDate: new Date(),
                endDate: new Date(),
                tableData: ''
			}
    
    componentDidMount = () => {
        var mytaskdata = Store.getStoreData('mytasks');
        console.log('MYTASKDATA', mytaskdata);
        this.setState({activitylog_data : mytaskdata, client_name : this.props.client_name})
        
    }
    calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
        return date_output;
    }
    getGroupCompanies =(company_id)=>{
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name ='';
        let gc_companies = this.props.gc_companies;
        let grp ={};    
        if(gc_companies.length > 0){
            for(var k = 0 ; k<gc_companies.length; k++){          
              grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
    }
   
    getDaysInMonth = (year, month) =>{
      return new Date(year, month, 0).getDate();
    }

    addMonths = (input, months) => {   
      months = isNaN(months) ? parseInt(-100) : months;
      const date = new Date(input)
      date.setDate(1)
      date.setMonth(date.getMonth() + months);
      date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
      return date;
    }
    processAcceptanceQueueData = () => {
        let lane_end = '';
        let taskData = this.state.activitylog_data;
        console.log('TASKDATA', taskData);
        var task_action_arr = []; 
        var task_action = {};
        for(let i = 0 ; i < taskData.length ; i ++){
            var taskjson = JSON.parse(taskData[i].task_json);
            var action_data = taskjson.action_data;         
                for(let j= 0 ;j < action_data.length; j++){
                    if(action_data[j].action in Activity_Shown) {
                        let lane_parts = action_data[j].action.split('_')
                        let lane_end = lane_parts[lane_parts.length - 1]
                        task_action.module_id = taskData[i].module_id;
                        task_action.activity_type = action_data[j].action;
                        task_action.client_id = taskData[i].company_id;
                        if(lane_end == 'CREATE'){
                             task_action.changed_by = taskData[i].contact_id;
                        }else{
                             task_action.changed_by = action_data[j].performed_by;
                        }
                        task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                        task_action.date_time_stamp = action_data[j].actiondate;
                        task_action.task_id = taskData[i].id
                        task_action_arr.push(task_action);
                        task_action = {};
                    }
                    
                }
        }

        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Activity Type', accessor: 'activity_type', minWidth: 370, headerStyle: {textAlign: 'left'}},      
                      {Header: 'Client Name', accessor: 'client_name', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed By', accessor: 'changed_by', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed Date', accessor: 'changed_date', minWidth: 170, headerStyle: {textAlign: 'left', visible:'false'}},
                    ];
        for(let k = 0 ; k< task_action_arr.length ; k++) 
        {
            let CompanyName = '';
            if(parseInt(task_action_arr[k].client_id) < 100000){
                CompanyName = this.state.client_name
            }else{
                CompanyName = this.getGroupCompanies(task_action_arr[k].client_id)
            }
            
              let elem = {'index': k,
                        'module_id': Source_Map[task_action_arr[k].module_id],
                        'activity_type' : Activity_Text[task_action_arr[k].activity_type],
                        'client_name' : CompanyName,
                        'changed_by' : this.props.contact_list[task_action_arr[k].changed_by],
                        'changed_date' : task_action_arr[k].changed_date,
                        'task_id' : task_action_arr[k].task_id   
              };
              ret.data.push(elem);
        } 
        Store.updateStore('table_cur_data', ret.data)
        return ret;      
      }

      refreshState = (view) =>{
        this.setState({modal_view : view})
      }
      changeModuleFilter = (event)=>{
        event.preventDefault();
        this.setState({ddlmodule_val: event.target.value});
      }
      handleEndChange = (date) => {
          if(date < this.state.startDate){
            alert('End date must be greater than start date');
            return;
          }
          this.setState({
            endDate: date
          });
      }
      changeDateFilter = (event)=>{
        event.preventDefault();
        this.setState({ddldate_val: event.target.value});
      }
      handleStartChange = (date) => {
          if(date > this.state.endDate){
            alert('Start date must be smaller than end date');
            return;
          }
          this.setState({
            startDate: date
          });
      }
      exportToCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        var cur_table_arr = [];
        for(let i = 0; i<  cur_table_data.length; i++){
          var container = [];
          container = Object.values(cur_table_data[i])
          if(container.lenght > 0); container.shift(); container.pop() 
          cur_table_arr.push(container)
        }
        let csvContent = "data:text/csv;charset=utf-8," 
            + cur_table_arr.map(e => e.join("  ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Activity_Log.csv");
        document.body.appendChild(link); 
        link.click();
      }
	render()
	{    
		return (
            <div style={{display:"inline-block" , width:"78%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "30px 30px 30px 30px" }}>
                {
                    (() => {
                        if(this.state.ddldate_val == 10000) {
                             return ( <div style={{position: "absolute", marginLeft:"300px", marginTop:"1px",  zIndex: "100"}}>
                              <div style={{display:"inline-block"}}>
                                          <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>Start Date : </label>
                                          <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartChange}/>
                              </div>
                              <div style={{display:"inline-block"}}>
                                          <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>End Date : </label>
                                          <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndChange}/>
                              </div>
                                          

                                          
                                      </div>);
                        }
                    })()
                }
                <div style={{float:"right"}}>
                
                  {/* <Dropdownlist id = "ddlmodule" onChange={this.changeModuleFilter} value={this.state.ddlmodule_val}>
                        <option value="0">All</option>
                        <option value="filechecker">File Checker</option>
                        <option value="rcm">RCM</option>
                        <option value="car">CAR</option>
                        <option value="cmp">CMP</option>
                        <option value="conflicts">Conflicts</option>
                    </Dropdownlist>  */}

                    {/* <Dropdownlist id = "ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                        <option value="1000">Any date</option>
                        <option value="1">Last Month</option>
                        <option value="6">Last Six Months</option>
                        <option value="12">Last Year</option>
                        <option value="10000">Custom</option>
                    </Dropdownlist>      */}
                </div>        
                <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={'Activity Log'} tableRows={20}/>  
                <ExportButton onClick={this.exportToCSV}>Export</ExportButton>   
                </div>
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><ActivityCompletedModal refreshParent={this.refreshState} modalData = {this.state.modal_data}/></div>;
                        }
                    })()
                }
            </div>         		  	 
		);
	}
}

export default ActivityLog;