import React from "react";
import styled from "styled-components";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Pagination from "./MCCPagination";
import "./mcc_table_styles.css";

const HeaderText = styled.div`
  margin-left: 5px;
  //margin-top: 10px;
  //margin-bottom: 10px;
  color: #c0c0c0;
  font-weight: 600;
  letter-spacing: 1px;
`;

const TableContainer = styled.div`
  background-color: #ffffff;
`;

class CSLTable extends React.Component {
  constructor(props) {
    super(props);
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    console.log("id", id);
    return row[id] !== undefined ? String(row[id]).includes(filter.value) : true;
  };  

  render() {
    // console.log('this.props', this.props);
    let tableData = this.props.processData();
    let tableRows = this.props.tableRows === null ? 5 : this.props.tableRows;
    // console.log("tableData", tableData);
    return (
      <TableContainer>
        <HeaderText>{this.props.headerText}</HeaderText>
        <div style={{ width: "100%" }}>
          <ReactTable
            showPagination={true}
            PaginationComponent={Pagination}
            data={tableData.data}
            columns={tableData.columns}
            defaultPageSize={tableRows}
            filterable={false}
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              // console.log('id',id);
              return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
            }}
          />
        </div> 
      </TableContainer>
    );
  }
}

export default CSLTable;