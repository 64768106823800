import React from 'react';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import APIContact from './Common/APIContact';
import Utils from './Common/Utils.js';
import ComDecom from './Common/ComDecom.js';
import Index from './Components/Index';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ModuleConfigHOC from './Components/Moduleconfig/ModuleConfigHOC';
import AuditlogsIndex from './Components/Logs/AuditLogsIndex';

import CSLLoader from './Components/Common/CSLLoader';
import AlertBox from './Components/Common/AlertBox';
import CSLPageloader from './Components/Common/CSLPageloader';
import Reload from './Common/Reload.js';
import EventBus from './Common/EventBus.js';

import authService from 'mcc-front-aux/dist/authService'

const moment = require('moment');
const last_action_time = moment().unix()
class AppContent extends React.Component
{
  state = {
    OverlayHeight: null,
    cardata: null,
    ready: false,
    OverlayTaskHeight: null,
    role: "noperm",
    refreshCount: 0,
    alert_param: null,
    task_loading: false
  };
  constructor(props) {
    super(props);
    Utils.setEnv('conflicts');
    this.callContact();
  }

  callContact() {
    let postData = { command: "contactlist"};
    let api = new APIContact();
    let j = api.command(postData, this.processContactData);
  }

  processContactData = (result) => {
    // localStorage.setItem("contactlist", JSON.stringify(result));
    let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
    result.result['contactlist'] = cl
    localStorage.setItem("contactlist", JSON.stringify(result));
  }

  processIndex = (result) => {
    if(result.error_code === 0) {

      console.log('tasks', result);
      if(result.result['role'] === "no_access") {
        // let alert_param = {title: 'No Access', message: 'You are not authorised to access this module. If you think this is in error, please contact your administrator.', ok_text: 'Ok', confirm: false,
        //                    alertHandler: this.alertHandler, stack: {id: 0}};
        // this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        let alert_param = {title: 'No Access', message: result.error_msg, ok_text: 'Ok', confirm: false,
                             alertHandler: this.alertHandler, stack: {id: 0}};
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
        return
      }
      Store.updateStore('cardata', result.result);
      Store.updateStore('role', result.result['role']);
      Store.updateStore('module_config', result.result['module_config']);
      Store.updateStore('contact_id', result.result['contact_id']);
      Store.updateStore('curCompanyID', result.result['curCompanyID']);
      Store.updateStore('conflictstype', result.result['conflictstype']);
      Store.updateStore('removaltype',result.result['removaltype']);
      Store.updateStore('myinitiatedtasks', result.result['my_initiated_tasks']);
      Store.updateStore('followup_tasks', result.result['followuptasks']);
      Store.updateStore('mytasks', result.result['my_tasks']);
      Store.updateStore('gc_company', result.result['gc_company']);
      Store.updateStore('gc_companies', result.result['gc_companies']);
      Store.updateStore('task_assign_user', result.result['task_assign_user']);
      //userStroage Section
      Store.updateStore('userroles', result.result['user_roles']);
      Store.updateStore('contact', result.result['contact']);
      Store.updateStore('contacts', result.result['contacts']);
      Store.updateStore('contactsemail', result.result['contractsemail']);
      Store.updateStore('reviewee_list', result.result.listcontacts);
      //userStroage Section

      let has_gc = result.result.gc_company.length > 1 ? true : false;
      Store.updateStore('has_gc',has_gc);

      let gc_assoc = {}
      let gc_filters = {};
      let gcid_and_gcname = {};
      for(let gc of result.result.gc_company) {
          let gc_id = gc.id.toString();
          gc_assoc[gc_id] = gc.company_name;
          if(!gc.is_gc){
            gcid_and_gcname[result.result['curCompanyID'].toString()] = gc.company_name;
          }else{
            const calculated_gc_id = (parseInt(result.result['curCompanyID']) * 100000) + parseInt(gc.id);
            gcid_and_gcname[calculated_gc_id.toString()] = gc.company_name;
          }
          if(gc.is_gc) {
            gc_filters[gc.id.toString()] = 1;
          }
      }
      console.log("gc_filters==>", gc_filters)
      Store.updateStore('gc_filters', gc_filters);
      Store.updateStore('gc_assoc', gc_assoc);
      Store.updateStore('gcid_and_gcname', gcid_and_gcname);

      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      console.log('cts==>', cts)
      let user_roles = {};
      let contactNameValue = '';
      let contacts_all = {};
      let contractsemail_all = {};
      let listcontacts_all = [];
      let contactid = result.result['contact_id'];
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['conflicts'];
        contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : '';
        for(let k in cts.result.contactlist){
          let clists =  cts.result.contactlist;
          if(clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
            contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
            let listConObject = {};
            listConObject.ID = cts.result.contactlist[k.toString()].ID;
            listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
            listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
            listcontacts_all.push(listConObject);
            if (user_roles[k.toString()] !== undefined && cts.result.user_roles['conflicts'][k.toString()] !== 'no_access' ) {
                contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
            }
          }
        }
        console.log("user_roles", user_roles);
        Store.updateStore('contact', contactNameValue);
        Store.updateStore('userroles', user_roles);
        Store.updateStore('contacts', contacts_all);
        Store.updateStore('contactsemail', contractsemail_all);
        Store.updateStore('reviewee_list', listcontacts_all);
      }
      switch(result.result['role']) {
        case 'admin_manager' : Store.updateStore('view', 'manager_index');break;
        case 'cm' : Store.updateStore('view', 'cm_index');break;
        case 'no_access' : Store.updateStore('view', 'no_access');break;
        default: Store.updateStore('view', 'team_index');
      }
      this.setState({ ready: true });

      let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;

    }else{
      // let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
      //            alertHandler: this.alertHandler, stack: {id: 0}}
      // this.setState({ ready: true, alert_param: alert_param});
        let alert_param = {title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
                   alertHandler: this.alertHandler, stack: {id: 0}}
        this.setState({ ready: true, alert_param: alert_param});
    }
  }

  alertHandler = (result, stack) => {
    // this.setState({alert_param: null})
    // window.location = '/select/ServiceBasedLanding';
    Reload.ReloadPage();
  }

  componentDidMount() {
    let windowUrl = window.location.href;
    EventBus.registerEvent('system_err', 'AppContent', this.system_err)
    if(windowUrl.indexOf('moduleconfig') != -1) {
      this.moduleIndexapi();
      console.log("moduleIndexapi")
    }else{
      this.callApi()
    }
  }

  system_err = (error) => {
    let alert_param = {
                            title: 'System Error',
                            message: error,
                            ok_text: 'OK',
                            confirm: false,
                            alertHandler: this.alertHandler,
                            stack: {id: 0}
                          }
        this.setState({ ready: true, alert_param: alert_param, task_loading: false});
  }

  moduleIndexapi(){
    let postData = { command: "moduleindex" };
    let api = new APICall();
    api.command(postData, this.processModuleIndex);
  }

  processModuleIndex = (result) => {
    console.log("result processModuleIndex", result)
    if(result.error_code === 0) {
      Store.updateStore('role', result.result['role']);
      Store.updateStore('gc_company', result.result['gc_company']);
      //userStroage Section
      Store.updateStore('userroles', result.result['user_roles']);
      Store.updateStore('contact', result.result['contact']);
      Store.updateStore('contacts', result.result['contacts']);
      Store.updateStore('contactsemail', result.result['contractsemail']);
      Store.updateStore('reviewee_list', result.result.listcontacts);
      //userStroage Section

      let has_gc = result.result.gc_company.length > 1 ? true : false;
      Store.updateStore('has_gc',has_gc);

      let r = {};
      r['result'] = {};
      let cts = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      console.log('cts==>', cts)
      let user_roles = {};
      let contactNameValue = '';
      let contacts_all = {};
      let contractsemail_all = {};
      let listcontacts_all = [];
      let contactid = result.result['contact_id'];
      if('contactlist' in cts.result && 'user_roles' in cts.result) {
        user_roles = cts.result.user_roles['conflicts'];
        contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : '';
        for(let k in cts.result.contactlist){
          let clists =  cts.result.contactlist;
            if(clists[k.toString()].IsActive === true && clists[k.toString()].IsDeleted === false){
            contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
            let listConObject = {};
            listConObject.ID = cts.result.contactlist[k.toString()].ID;
            listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
            listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
            listcontacts_all.push(listConObject);
            if (cts.result.user_roles['conflicts'][k.toString()] !== 'no_access' ) {
                contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
            }
          }
        }
        console.log("user_roles", user_roles);
        Store.updateStore('contact', contactNameValue);
        Store.updateStore('userroles', user_roles);
        Store.updateStore('contacts', contacts_all);
        Store.updateStore('contactsemail', contractsemail_all);
        Store.updateStore('reviewee_list', listcontacts_all);
      }
      this.setState({ ready: true });
    }else{
      // let alert_param = {title: 'System Error', message: 'Please contact your manager or Compliancy Services customer support on: Tel: 01462 510022 or at regtech@compliancy-services.co.uk.', ok_text: 'Ok', confirm: false,
      //            alertHandler: this.alertHandler, stack: {id: 0}}
      // this.setState({ ready: true, alert_param: alert_param});
        let alert_param = {title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
                   alertHandler: this.alertHandler, stack: {id: 0}}
        this.setState({ ready: true, alert_param: alert_param});
    }
  }

  callApi(){
    let postData = { command: "index" };
    let api = new APICall();
    Utils.log('sending first call')
    api.command(postData, this.processIndex)
    let task_loading = true;
    this.setState({task_loading : task_loading})
  }

  refreshState = () => {
    this.setState({refreshCount : this.state.refreshCount + 1})
  }

  render()
  {
    let task_loading = this.state.task_loading;
    if(task_loading){
      if(Store.getStoreData('conflicts-index') === null || localStorage.getItem('contactlist') === null) {
        return <div><CSLPageloader /></div>
      }
    }
    if(!this.state.ready) {
      return <div><CSLLoader /></div>
    }
    if(this.state.alert_param !== null) {
      return <AlertBox alertParam = {this.state.alert_param} />
    }
    return(
      <Router>
        <Switch>
        <Route path="/auditlogs" exact>
            {/* <AuditlogsIndex /> */}
          </Route>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/moduleconfig/:requester" exact component={ModuleConfigHOC} />
          <Route path="/" exact>
            <Index refreshCallback={this.refreshState} noParam={true} />
          </Route>
          <Route path="/:taskId" component={Index} exact />
        </Switch>
      </Router>
    );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {

    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

    return <AppContent />
  }
}

export default App;
