import React from 'react';
import Store from '../../Common/Store'
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import General from './General';
import ConflictTypes from './ConflictTypes'
import Permissions from './Permissions';
import APICall from '../../Common/APICall.js';
import { Link } from 'react-router-dom';
import Email from './Email'
import CSLLoader from '../Common/CSLLoader';
import Reload from '../../Common/Reload.js';
import AlertBox from '../Common/AlertBox';
import CustomFields from './CustomFields';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: #222222;
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 100%;
    height: 60px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const ReviewTab_inactive = styled.div`
    background-color: #ffffff;
    color: #dedede;
    padding: 10px 20px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    float: left;
`;

class ModuleConfigHOC extends React.Component
{
	state = {
		active_section_id: "general",
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        ready: false,
        alert_param: null
	};

	constructor(props) {
        super(props);
        if (Store.getStoreData('role') === 'admin_manager') {
            const postData = { command: "list_company_and_users" };
            const api = new APICall();
            api.command(postData, this.getInitialPermission);
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        //console.log("requester:", requester);
        let contactlist = JSON.parse(localStorage.getItem('contactlist'));
        let user_roles = contactlist.result.user_roles['conflicts'];
        let cls = contactlist.result.contactlist;

        let company_users = [];

        for(let d of result.activeUsers){
            let cu = {};
            cu['email'] = d.EmailAddress;
            cu['user_id'] = d.ID;
            cu['name'] = d.ContactName;
            cu['role'] = d.ID in user_roles ? user_roles[d.ID] : 'no_access';
            company_users.push(cu)
        }
        console.log("company_users===>", company_users);
        console.log("result ===>", result);
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(company_users));
        this.setState({permissions, all_company_users, module_config: result.moduleconfig, requester,
            ready: true});
    }

	returnSection = (section) => (event) => {
        event.preventDefault();
        this.setState({active_section_id: section});
	}

	updateGeneral = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    changeActiveInactive = (obj) => {
        let {module_config} = this.state;
        module_config.general.reason_active_inactive = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    saveReasonValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.reason_removal.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddReasonValue);
    }
    afteraddReasonValue = (result) => {
        console.log(result);
    //     alert("Reason for removal added successfully")
    //    this.setState({module_config: result.result});
        let alert_param = {title: 'Success', message: 'Reason for removal added successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
   }

    updateReason = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_removal = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdatereasonValue);
    }
    afterUpdatereasonValue = (result) => {
        console.log(result);
    //     alert("Reason for removal updated successfully")
    //    this.setState({module_config: result.result});
        let alert_param = {title: 'Success', message: 'Reason for removal updated successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
   }

   deleteReasonValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.reason_removal = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeletereasonValue);
    }
    afterDeletereasonValue = (result) => {
        console.log(result);
        // alert("Reason for removal deleted successfully")
        // this.setState({module_config: result.result});
        let alert_param = {title: 'Success', message: 'Reason for removal deleted successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
    }

    afterUpdate = (result) => {
         console.log(result);
        // alert(result.error_msg);
        // this.setState({module_config: result.result});
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
        // window.location.reload();
    }

    afterRoleUpdate = (result) => {
        // alert(result.error_msg);
        let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({alert_param: alert_param});
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    updateEmailRemainder = (obj) => {
        let {module_config} = this.state;
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
    }

    afterUpdateEmailRemainder = (result) => {
        console.log(result);
       this.setState({module_config: result.result});
   }

    updatePermissions = (obj, single_obj) => {
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        this.setState({permissions: obj, changed_user_roles});
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }else{
            permissions.company_users = this.state.all_company_users;
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = (event) => {
        event.preventDefault();
        let {module_config,changed_user_roles} = this.state;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.command(postData, this.afterUpdate);
        }
    }

    closePage = (event) => {
        event.preventDefault();
        // console.log("requester:", this.state.requester);
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
    }

    updateConflictTypeJson = (conflicts) => {
        let {module_config} = this.state;
        module_config.conflict_types = conflicts;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateConflictType);
    }

    afterUpdateConflictType = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Conflict type updated successfully', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
    }
    updateafterallProcesscomplete = (result, stack) => {
        console.log(result,stack)
        this.setState({alert_param: null})
    }

    updateEditconflitsType = (conflicts) => {
        let {module_config} = this.state;
        // console.log("saving:", changed_user_roles);
        module_config.conflict_types = conflicts;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        api.command(postData, this.aftereditconflictUpdate);
    }

    aftereditconflictUpdate = (result) => {
        console.log(result);
    //    alert("Conflict type updated successfully.");
    //    this.setState({module_config: result.result});
       let alert_param = {title: 'Success', message: 'Conflict type updated successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
       // window.location.reload();
   }

    updateaddconflitsType = (conflicts) => {
        let {module_config} = this.state;
        // console.log("saving:", changed_user_roles);
        module_config.conflict_types = conflicts;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        api.command(postData, this.afteraddconflictUpdate);
    }

    afteraddconflictUpdate = (result) => {
        console.log(result);
    //    alert("Conflict type added successfully.");
    //    this.setState({module_config: result.result});
        let alert_param = {title: 'Success', message: 'Conflict type added successfully.', ok_text: 'Ok', confirm: false,
            alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
   }

    deleteConflictTypeValue = (conflicts) => {
        let {module_config} = this.state;
        module_config.conflict_types = conflicts;
        console.log("modulecong",module_config)
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config };
        api.command(postData, this.afterDeleteConflictUpdate);
    }

    afterDeleteConflictUpdate = (result) => {
        console.log(result);
    //    alert("Conflict type deleted successfully.");
    //    this.setState({module_config: result.result});
       let alert_param = {title: 'Success', message: 'Conflict type deleted successfully.', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
       // window.location.reload();
   }

    exportToCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        console.log("cur_table_data",cur_table_data)
        let cur_table_data_change = [];
        let headerCSV = {type: "Conflict Type", status: "Status", id: "1234"}
        cur_table_data.unshift(headerCSV);
        for(let k = 0; k < cur_table_data.length; k++) {
            let cont = {
                "type" : cur_table_data[k].type,
                "status" : cur_table_data[k].status ,
                "id" :  cur_table_data[k].id
            };
            cur_table_data_change.push(cont);
        }
        var cur_table_arr = [];

        for(let i = 0; i<  cur_table_data.length; i++){
          var container = [];
          container = Object.values(cur_table_data_change[i])
          if(container.length > 0);
        //   container.shift();
            container.pop()

          cur_table_arr.push(container)
        }
        console.log("cur_table_arr",cur_table_arr)
        let csvContent = "data:text/csv;charset=utf-8,"
            + cur_table_arr.map(e => e.join(", ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Conflict_Types.csv");
        document.body.appendChild(link);
        link.click();
      }

    updateCustomFields = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterCustomUpdate);
	}

    afterCustomUpdate = (result) => {
        console.log(result);
       let alert_param = {title: 'Success', message: result.error_msg, ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
   }

    updateCustomFieldsToggle = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateEmailRemainder);
	}



   closePageCustomField = () => {
        //window.close();
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
    }

    saveCustomFieldsValue = (obj) => {
        console.log("object",obj);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields.push(obj)
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afteraddCustomFieldValue);
    }
    afteraddCustomFieldValue = (result) => {
        console.log(result);
        let alert_param = {title: 'Success', message: 'Custom Field added successfully', ok_text: 'Ok', confirm: false,
                        alertHandler: this.updateafterallProcesscomplete, stack: {}}
        this.setState({module_config: result.result, alert_param: alert_param});
    }

    deleteCustomFieldsValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteCustomFieldsValue);
    }
    afterDeleteCustomFieldsValue = (result) => {
        console.log(result);
        //alert("Reason for Breach deleted successfully")
        let alert_param = {title: 'Success', message: 'Custom deleted successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}

    updateCustomFieldsValue = (arr) => {
        console.log("object",arr);
        let {module_config} = this.state;
        module_config.custom_fields.additional_fields = arr;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdateCustomFieldsValue);
    }
    afterUpdateCustomFieldsValue = (result) => {
        console.log(result);
        //alert("Reason for Breach updated successfully")
        let alert_param = {title: 'Success', message: 'Custom updated successfully', ok_text: 'Ok', confirm: false,
                           alertHandler: this.updateafterallProcesscomplete, stack: {}}
		this.setState({module_config: result.result, alert_param: alert_param});
	}
    updateAdditional_fields = (obj) => {
		let {module_config} = this.state;
		module_config.custom_fields.additional_fields = obj;
		const postData = {command: "save_module_config", module_config: module_config};
		const api = new APICall();
		api.command(postData, this.afterUpdateEmailRemainder);
    }

    render()
	{  if (Store.getStoreData('role') !== 'admin_manager') {
		return (
			<ErrorBar>
				<div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
				<div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
				<div style={{clear: "both"}}></div>
			</ErrorBar>
		);
	}
	if (this.state.module_config === null) {
		return (<div><CSLLoader /></div>);
    }
	return (
		<div style={{padding: "10px 10px 20px 10px"}}>
			<CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
			<div style={{clear: "both"}}></div>
			<ReviewBodyContainer>
				<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
					<ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px"}}>
						{
							(() => {
								if (this.state.active_section_id === "general") {
									return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
								} else {
									return (<div style={{float: "left"}}>General</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
						{
							(() => {
								if (this.state.active_section_id === "email") {
									return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
								} else {
									return (<div style={{float: "left"}}>Email</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("conflict_types")} style={{marginTop: "2px"}}>
						{
							(() => {
								if (this.state.active_section_id === "conflict_types") {
									return (<div style={{float: "left", fontWeight: "600"}}>Conflict Types</div>);
								} else {
									return (<div style={{float: "left"}}>Conflict Types</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
                    <ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px"}}>
						{
							(() => {
								if (this.state.active_section_id === "custom_fields") {
									return (<div style={{float: "left", fontWeight: "600"}}>Custom Fields</div>);
								} else {
									return (<div style={{float: "left"}}>Custom Fields</div>);
								}
							})()
						}
						<div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
						<div style={{clear: "both"}}></div>
					</ReviewTab>
				</ReviewLeftContainer>
				<ReviewRightContainer>
					{
						(() => {
							if (this.state.active_section_id === "general") {
								return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} reason_for_removal={this.state.module_config.reason_removal} updateGeneral={this.updateGeneral} saveReasonValue={this.saveReasonValue} updateReason={this.updateReason} deleteReasonValue={this.deleteReasonValue} changeActiveInactive={this.changeActiveInactive}/>);
                            }
                            if (this.state.active_section_id === "email") {
                                return (<Email email={JSON.parse(JSON.stringify(this.state.module_config.email))} variables={this.state.module_config.variables} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder} updateEmail={this.updateEmail}/>);
                            }
							if (this.state.active_section_id === "conflict_types") {
								return (<ConflictTypes client_conflict_types={this.state.module_config.conflict_types} updateConflictTypes={this.updateConflictTypeJson} editValueSave={this.updateEditconflitsType} addValueSave={this.updateaddconflitsType} deleteConflictValueSave={this.deleteConflictTypeValue}  />);
							}
                            if (this.state.active_section_id === "custom_fields") {
								return (<CustomFields custom_fields={this.state.module_config.custom_fields} updateCustomFields={this.updateCustomFields} updateCustomFieldsToggle={this.updateCustomFieldsToggle} closePage={this.closePageCustomField} saveCustomFieldsValue={this.saveCustomFieldsValue} deleteCustomFieldsValue={this.deleteCustomFieldsValue} updateCustomFieldsValue={this.updateCustomFieldsValue} updateAdditional_fields={this.updateAdditional_fields}/>);
							}
							if (this.state.active_section_id === "permissions") {
								return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
							}
						})()
					}
                    {
                        (() => {
                            if (this.state.active_section_id === "conflict_types") {
                                return (
                                    <FooterButtonsContainer>
                                        <ExportButton onClick={this.exportToCSV}>Export</ExportButton>
                                    </FooterButtonsContainer>
                                );
                            }
                            if (this.state.active_section_id !== "conflict_types" && this.state.active_section_id !== "email" && this.state.active_section_id !== "general" && this.state.active_section_id !== "custom_fields") {
                                return (
                                    <FooterButtonsContainer>
                                        <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                                        <SaveBtn onClick={this.updateModuleconfig}>Save</SaveBtn>
                                    </FooterButtonsContainer>
                                )
                            }
                        })()
                    }


				</ReviewRightContainer>
				<div style={{clear: "both"}}></div>
			</ReviewBodyContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
		</div>
	);
}
}

export default ModuleConfigHOC;
