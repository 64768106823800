import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import * as Style from '../StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from '../../Common/CSLTable.js'
import APICall from '../../Common/APICall.js';
import EmailModal from './EmailModal';
import DatePicker from 'react-date-picker';
//import "react-datepicker/dist/react-datepicker.css";

const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;
const Source_Map = {conflicts: 'Conflict Register'}

class DataChangeLog extends React.Component
{
	state = { 
                data_change_log : {},
                client_name : '',
                ddlmodule_val : 0,
                ddldate_val : 'anydate',
                startDate: new Date(),
                endDate: new Date()
	}
    
  componentDidMount = () => {
        this.setState({data_change_log : this.props.data_change_log, client_name : this.props.client_name})      
  }
  calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
        return date_output;
  }
  getGroupCompanies =(company_id)=>{
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name ='';
        let gc_companies = this.props.gc_companies;
        let grp ={};    
        if(gc_companies.length > 0){
            for(var k = 0 ; k<gc_companies.length; k++){          
              grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
  }
  processAcceptanceQueueData = () => {
        let lane_end = '';
        let dataCahngeLog = this.state.data_change_log;
        console.log("Result_changelog", dataCahngeLog[0]);
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Item', accessor: 'item', minWidth: 270, headerStyle: {textAlign: 'left'}},      
                      {Header: 'From', accessor: 'from', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'To', accessor: 'to', minWidth: 230, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed By', accessor: 'changed_by', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                      {Header: 'Changed Date', accessor: 'changed_date', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                    ];
                    for(let k = 0 ; k< dataCahngeLog.length ; k++) 
                    {
                        let updated_at_arr = this.calculateDateInTimestamp(dataCahngeLog[k].updated_at).split('/')
                        let updated_at = new Date(dataCahngeLog[i].updated_at);
                        let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
                        let start_date = this.state.startDate;
                        let endDate = this.state.endDate;
                        let updated_at_int = parseInt(updated_at_arr[2]) * 10000 + parseInt(updated_at_arr[1]) *100 + parseInt(updated_at_arr[0]);
                        console.log("updated_at",updated_at_int)
                        let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() +1) *100 + compare_date.getDate();
                        if(this.state.ddldate_val == 10000){
                          let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() +1) * 100 + start_date.getDate();
                          let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() +1) * 100 + endDate.getDate();

                          if(updated_at_int>= start_date_int && updated_at_int <= end_date_int){
                              let elem = {'index': k,
                                          'module_id': Source_Map[dataCahngeLog[k].module_id],
                                          'item' : dataCahngeLog[k].field_name,
                                          'from' : dataCahngeLog[k].field_value_prev,
                                          'to' : dataCahngeLog[k].field_value,
                                          'changed_by' : this.props.contact_list[dataCahngeLog[k].contact_id],
                                          'changed_date' : this.calculateDateInTimestamp(dataCahngeLog[k].updated_at),
                                         };
                              ret.data.push(elem);
                          }
                        }else if(updated_at_int >= compare_date_int){
                              let elem = {'index': k,
                                          'module_id': Source_Map[dataCahngeLog[k].module_id],
                                          'item' : dataCahngeLog[k].field_name,
                                          'from' : dataCahngeLog[k].field_value_prev,
                                          'to' : dataCahngeLog[k].field_value,
                                          'changed_by' : this.props.contact_list[dataCahngeLog[k].contact_id],
                                          'changed_date' : this.calculateDateInTimestamp(dataCahngeLog[k].updated_at),
                                         };
                              ret.data.push(elem);
                        }
                    }
        Store.updateStore('table_changed_data', ret.data)
        return ret;      
  }
  refreshState = (view) =>{
        this.setState({modal_view : view})
  }
  getDaysInMonth = (year, month) =>{
    return new Date(year, month, 0).getDate();
  }

  addMonths = (input, months) => {   
    months = isNaN(months) ? parseInt(-100) : months;
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months);
    date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
    return date;
  }
  handleEndChange = (date) => {
      if(date < this.state.startDate){
        alert('End date must be greater than start date');
        return;
      }
      this.setState({
        endDate: date
      });
  }
  changeDateFilter = (event)=>{
    event.preventDefault();
    this.setState({ddldate_val: event.target.value});
  }
  handleStartChange = (date) => {
      if(date > this.state.endDate){
        alert('Start date must be smaller than end date');
        return;
      }
      this.setState({
        startDate: date
      });
  }
  exportToCSV  = (event)=>{
    event.preventDefault();
    var count = 0;
    var cur_table_data = Store.getStoreData('table_changed_data');
    //console.log("cur_table_data.length", cur_table_data)
    var cur_table_arr = [];
    for(let i = 0; i<  cur_table_data.length; i++){
      
      var container = [];
      container = Object.values(cur_table_data[i])
      if(container.lenght > 0); container.shift(); 
      
      cur_table_arr.push(container);
      count ++;
    }
    console.log("cur_table_data.length", count)
    if(cur_table_arr.length == cur_table_data.length)
    {
      let csvContent = "data:text/csv;charset=utf-8," 
          + cur_table_arr.map(e => e.join("  ")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Changed_Log.csv");
      document.body.appendChild(link); 
      link.click();
    }
  }
	render()
	{    
		return (
            <div style={{display:"inline-block" , width:"84%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "17px" }}> 
                    {
                        (() => {
                            if(this.state.ddldate_val == 10000) {
                                 return ( 
                                  <div style={{position: "absolute", marginLeft:"250px", marginTop:"1px", zIndex: "100"}}>
                                    <div style={{display:"inline-block"}}>
                                                <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>Start Date : </label>
                                                <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartChange}/>
                                    </div>
                                    <div style={{display:"inline-block"}}>
                                                <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>End Date : </label>
                                                <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndChange}/>
                                    </div>  
                                  </div>);
                            }
                        })()
                    }
                    <div style={{float:"right"}}>
                      {/* <Dropdownlist id = "ddlmodule" onChange={this.changeModuleFilter} value={this.state.ddlmodule_val}>
                            <option value="0">All</option>
                            <option value="filechecker">File Checker</option>
                            <option value="rcm">RCM</option>
                            <option value="car">CAR</option>
                            <option value="cmp">CMP</option>
                        </Dropdownlist> 
                        <Dropdownlist id = "ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                            <option value="1000">Any date</option>
                            <option value="1">Last Month</option>
                            <option value="6">Last Six Months</option>
                            <option value="12">Last Year</option>
                            <option value="10000">Custom</option>
                        </Dropdownlist>      */}
                    </div>                          
                    <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={'Change Log'} tableRows={20}/> 
                    <ExportButton onClick={this.exportToCSV}>Export</ExportButton>    
                </div>
            </div>         		  	 
		);
	}
}

export default DataChangeLog;