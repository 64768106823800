import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
import ConflictsList from './ConflictsList';
import TeamAskConflictModal from './TeamAskConflictModal';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;


class TeamIndex extends React.Component
{
    state = {
        isLoaded: false,
        pending_tasks: [],
        showModal: null
    };
    constructor(props) {
        super(props);
    }

    // refreshCallback = () => {
    //     this.props.refreshCallback();
    // }

    showAskQuestionModal = () => {
        Store.updateStore('OverlayHeight', `${document.body.scrollHeight.toString()}px`);
        Store.updateStore('view','team_ask_modal');
        this.props.refreshCallback();
    }

    showConflictModal = (row) => (event) => {       
        event.preventDefault();
        let index = row.index === null ? 0 : row.index;
        console.log('row in showAskQuestionModal', row);
        console.log('index in showAskQuestionModal', index);
        let q_index = index;
        Store.updateStore('q_index', q_index);
        this.setState({showModal: "teamAskConflictModal"})
    }
    closeModal = (event) => {
        //event.preventDefault();
        this.setState({
            showModal: null
        })
    }

    render()
    {        
        Store.updateStore('calling_view', 'team_index');
        let task_id = this.props.taskId !== 0 ? this.props.taskId : 0;
        console.log("task_id2===>",task_id)
        return (
            <IndexContainer>
                {
                    (() => {
                        if(task_id === 0) {
                            return(<SubmitButton onClick={this.showConflictModal({index: -1})}>Disclose New Conflict</SubmitButton>)
                        }
                    })()
                }               
                <ConflictsList add={false} taskId={task_id} refreshCallback={this.refreshCallback} />
                {
                (() => {
                    if (this.state.showModal !== null) {
                        switch(this.state.showModal) {
                            case 'teamAskConflictModal' : return <TeamAskConflictModal closeModal={this.closeModal} />; break;
                        }
                    }
                })()
                }
            </IndexContainer>
        );
    }
}
export default TeamIndex;